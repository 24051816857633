@import "normalize";
@import "mobileMenu";

//noinspection CssInvalidAtRule
@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700&display=swap');
$background-green-default: #759A8A;
$background-green-active: #528D74;
$light-green-color: #EEFFF9;
$green-text-default: #759A8A;

$main-black-color: #28343B;
$main-dark-blue: #475170;
$main-white-color: #FAFAFA;
$main-light-blue: #DFE3EF;
$main-warning-color: #FF4A4A;
$main-light-grey: #F6F7FB;
$text-blue-color: #1B84FF;
$text-warning-color: #FF0000;
$text-dark-color: #4A4545;
$light-grey-color: #C4C4C4;
$light-border-color: #D6D8E0;
$success-green: #37C15E;
$button-blue-color: #378FC1;
$button-message-blue-color: #B5C5FF;
$pink-color: #FEBEBE;
$main-rating-background-color: #ADE8F4;
$main-rating-scale-color: #0077B6;
$second-rating-background-color: #D4E8EC;
$second-rating-scale-color: #475170;

@mixin box-shadow-with-border-radius {
	box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.15), 5px 5px 10px rgba(160, 160, 160, 0.25), 10px 10px 20px rgba(160, 160, 160, 0.5), -10px -10px 20px rgba(255, 255, 255, 0.1);
	border-radius: 20px;
	background-color: #FFFEFC;
}

@mixin inner-shadow-top {
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 254, 252, 0.5) 100%);
	box-shadow: inset 0 2px 0 rgba(151, 151, 151, 0.5), inset 0 3px 2px rgba(151, 151, 151, 0.25);
	border-radius: 10px;
}

body {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	line-height: 1.5;
	font-weight: 400;
	color: $text-dark-color;

	&.overflow-hidden {
		overflow: hidden;
	}

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background-color: #e0e0e0;
		border-radius: 2.3px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #4A4545;
		border-radius: 2.3px;
	}
}

.hidden {
	display: none !important;
}

#scroll-to-top {
	position: fixed;
	bottom: 50px;
	right: 5px;
	width: 50px;
	height: 50px;
	border: 1px solid $main-white-color;
	border-radius: 50%;
	background-color: $background-green-default;
	z-index: 10;

	svg {
		font-size: 40px;
		fill: $main-white-color;
	}
}

.button {
	background-color: $background-green-default;
	color: $main-white-color;
	font-weight: 400;
	font-size: 18px;
	border-radius: 10px;

	&:hover {
		box-shadow: 0 0 6px $background-green-default;
	}

	&:active,
	&:focus-visible {
		background-color: $background-green-active;
		box-shadow: none;
	}

	&:disabled {
		background-color: $main-white-color;
		color: #D6D8E0;

		&:hover {
			box-shadow: none;
		}
	}
}

.not-found-page {
	position: relative;
	height: 80vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(180deg, rgba(255, 252, 241, 0.25) 0%, rgba(255, 252, 241, 0.25) 11.58%, rgba(167, 186, 174, 0.125) 28.47%, rgba(167, 186, 174, 0.25) 89.92%), #FFFFFF;

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 744px;
		width: 100%;
		max-height: 744px;
		height: 100%;
		background: rgba(255, 255, 255, 0.75);
		filter: blur(120.457px);
	}

	.wrapper {
		position: relative;
		z-index: 1;

		svg {
			max-width: 300px;
		}

		.text-block {
			position: relative;
			top: -50px;
		}

		p {
			text-align: center;
		}

		p:first-of-type {
			font-weight: 300;
			font-size: 128px;
			line-height: 1;
			color: $background-green-default;
			text-shadow: -5px 6px #D6D8E0;
		}

		p:last-of-type {
			font-weight: 400;
			font-size: 36px;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: #979494;
		}
	}
}

.auth-page {
	.content {
		min-height: 80vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 50px 0;
	}

	.closeButtonWrapper {
		display: none;
		width: 0;
		height: 0;
	}
}

.userMainMenu {
	ul {
		li {
			padding: 0;

			a,
			p {
				padding: 6px 16px;
				color: inherit;
				width: 100%;
			}

			&:last-of-type {
				position: relative;
				color: red;
				border-top: 3px solid #979494;
				padding-top: 4px;
			}

			&:last-of-type::after {
				content: "";
				width: 24px;
				height: 24px;
				position: absolute;
				right: 50%;
				background-image: url("../image/icons/exit.svg");
			}
		}
	}
}

.container {
	width: 100%;
	overflow: hidden;
	box-shadow: 0 0 40px -20px rgba(0, 0, 0, .2);
	margin: auto;
	background: linear-gradient(180deg, rgba(255, 252, 241, 0.25) 0%, rgba(255, 252, 241, 0.25) 23.81%, rgba(167, 186, 174, 0.125) 74.84%, rgba(167, 186, 174, 0.25) 98.89%), #FFFFFF;
}

.create-an-order {
	color: $light-green-color;
	background-color: #FB8232;
	border-radius: 10px;

	&:hover {
		box-shadow: 0 0 10px -3px #FB8232;
		transition: box-shadow .1s linear;
	}

	&:active {
		background-color: #fc7b27;
	}
}

.link,
.link-black {
	position: relative;
	overflow-x: hidden;
	padding-bottom: 1px;

	&::before {
		content: "";
		height: 1px;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		transform: scaleX(0);
		transform-origin: right bottom;
		transition: transform 0.4s ease-in-out 0s, -webkit-transform 0.4s ease-in-out 0s;
	}

	&:hover::before,
	&:focus-visible::before {
		transform-origin: left top;
		transform: scaleX(1);
	}
}

.link {

	&::before {
		background-color: $light-green-color;
	}
}

.link-black {

	&::before {
		background-color: $main-black-color;
	}
}

.try-service-kart-block {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: rgba(255, 165, 61, 0.06);
	padding: 50px 5px 100px 5px;

	.content {
		width: 100%;
	}

	h2 {
		color: $green-text-default;
		margin-bottom: 35px;
	}

	p {
		font-weight: 500;
		font-size: 20px;
		text-align: center;
		margin-bottom: 5px;
	}

	.button-block {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	p.or {
		margin: 10px 15px;
	}

	.create-an-order {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		max-width: 420px;
		width: 100%;
		height: 70px;
		font-weight: 600;
		font-size: 22px;
		color: $main-white-color;
	}

	.login-by-specialist {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		max-width: 420px;
		width: 100%;
		height: 70px;
		font-weight: 600;
		font-size: 22px;
		background-color: #FFFEFC;
		box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.15), 5px 5px 10px rgba(160, 160, 160, 0.25), 10px 10px 20px rgba(160, 160, 160, 0.5), -10px -10px 20px rgba(255, 255, 255, 0.1);
		border-radius: 10px;
	}
}

header.MuiAppBar-root {
	background-color: inherit;
	box-shadow: none;

	&.sticky {
		width: 100%;
		background-color: #FFFFFF;
		position: fixed;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		box-shadow: 0 15px 20px rgba(0, 0, 0, .1);
		z-index: 100;
		transition: top .2s linear;
		animation-name: sticky__header;
		animation-duration: .2s;
	}

	.content {
		position: relative;
		width: 100%;
		margin: auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px;

		& > div:first-of-type {
			flex-direction: row;
			justify-content: space-between;
		}

		.central-block {
			max-width: 1000px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			flex-grow: 1;
			padding: 0 10px;
		}

		.logo-link {
			display: flex;
			max-width: 140px;
			height: 33px;
		}

		.dynamic-icons-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		/*.left-block {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}

		.right-block {
			position: absolute;
			top: 90px;
			right: -100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			background-color: #dcdcdc;
			width: 100vw;
			height: 100vh;
			padding: 20px;
			z-index: 1000;
			transition: right .2s linear;

			&.collapse {
				right: 0;
			}
		}*/

		.login-button-group {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 100%;

			button {
				font-size: 16px;
				line-height: 1.5;
				letter-spacing: .3px;
				font-weight: 300;
			}

			.login {
				width: 106px;
				position: relative;
				margin-right: 5px;
				padding: 8px 16px;

				&:hover,
				&:focus-visible {
					background-color: #F5F5F5;
					border-radius: 10px;
				}
			}

			.signUp {
				width: 140px;
				background-color: $background-green-default;
				border-radius: 10px;
				color: $main-white-color;
				padding: 8px 16px;

				&:hover,
				&:focus-visible {
					transform: scale(1.01);
					background-color: $background-green-active;
				}
			}
		}
	}
}

header .content,
#menu-appbar {
	button.create-an-order {
		width: 182px;
		height: 35px;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;
	}

	.language-button-group {
		display: flex;
		flex-direction: row;

		.de, .en {
			width: 35px;
			height: 23px;
			text-transform: uppercase;
			background: $main-white-color linear-gradient(to top, rgba(151, 151, 151, .1) 0%, rgba(255, 255, 255, 1) 100%);
			font-size: 16px;
			line-height: 1.5;
			font-weight: 300;

			&:disabled {
				cursor: default;
			}
		}

		.de {
			border-radius: 7px 0 0 7px;
		}

		.en {
			border-radius: 0 7px 7px 0;
		}

		.active {
			box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, .2);
			background: linear-gradient(to bottom, rgba(151, 151, 151, .1) 0%, rgba(255, 255, 255, 1) 100%);
		}
	}
}

@keyframes sticky__header {
	0% {
		top: -70px;
		opacity: 0;
	}
	100% {
		top: 0;
		opacity: 1;
	}
}

.content {
	margin: auto;
	padding: 0 10px;
}

h1 {
	text-align: center;
	font-size: 20px;
	line-height: 1.3;
	font-weight: 600;
	color: $text-dark-color;
	margin-top: 0;
	margin-bottom: 5px;

	span {
		color: #7A7575;
	}
}

h2 {
	font-size: 36px;
	font-weight: 600;
	line-height: 1.5;
	text-align: center;

	span {
		color: $background-green-default;
	}
}

.homepage {
	position: relative;
}

.search-block {
	position: relative;
	max-width: 600px;
	width: 100%;
	margin: 0 auto 50px auto;

	.search-group {
		position: relative;

		input.search {
			max-width: inherit;
			width: 100%;
			height: 60px;
			font-size: 16px;
			line-height: 1.5;
			font-weight: 300;
			color: rgba(74, 69, 69, 0.7);
			border: 4px solid #979494;
			border-radius: 50px;
			padding: 18px 60px 18px 30px;
		}

		button.search-button {
			position: absolute;
			top: 5px;
			right: 5px;
			width: 50px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: $background-green-default;
			border-radius: 50%;
		}
	}

	span.example {
		display: block;
		font-size: 16px;
		line-height: 1.5;
		font-weight: 300;
		color: rgba(74, 69, 69, 0.65);
		margin: 10px 0 0 30px;
	}
}

.banner-block {
	position: relative;
	margin-bottom: 55px;

	h3 {
		position: absolute;
		top: 50%;
		right: 50px;
		font-size: 72px;
		font-weight: 700;
		line-height: 1;
		color: #A4A2A2;
		transform: translateY(-50%);
	}

	.swiper {
		padding-bottom: 37px !important;
		z-index: 0;

		.custom-swiper-button-next,
		.custom-swiper-button-prev {
			width: 30px;
			height: 30px;
			position: absolute;
			top: 45%;
			transform: translate(-50%, -50%);
			cursor: pointer;
			z-index: 10;
		}

		.custom-swiper-button-next {
			background: url("../image/icons/right-arrow-svg.svg") no-repeat;
			background-size: 100%;
			right: 0;
		}

		.custom-swiper-button-prev {
			background: url("../image/icons/left-arrow-svg.svg") no-repeat;
			background-size: 100%;
			left: 20px;
		}

		.custom-swiper-button-next.swiper-button-disabled,
		.custom-swiper-button-prev.swiper-button-disabled {
			display: none;
		}

		img {
			width: 100%;
			max-height: 720px;
			object-fit: contain;
			object-position: center;
		}

		.swiper-pagination-bullet {
			width: 12px;
			height: 12px;
			margin-left: 0 !important;
			margin-right: 5px !important;
			background-color: $background-green-default;
			opacity: 1;

			&:last-of-type {
				margin-right: 0 !important;
			}

			&.swiper-pagination-bullet-active {
				background-color: $text-dark-color;
			}
		}
	}
}

.categories-block {
	margin-bottom: 55px;

	.category-button-block {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		max-width: 990px;
		margin: auto;

		.category-button {
			width: 300px;
			height: 200px;
			font-size: 18px;
			font-weight: 400;
			line-height: 1;
			color: $text-dark-color;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			background: #F9FBF9;
			box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(160, 160, 160, 0.25), 10px 10px 20px rgba(160, 160, 160, 0.5), -10px -10px 20px #FFFFFF;
			border-radius: 32px;
			margin-bottom: 30px;
			margin-right: 15px;
			margin-left: 15px;
			padding: 20px;

			.category {
				width: 100%;
				font-size: 19px;
				font-weight: 500;
				text-align: center;
				line-height: 1.5;
				color: #FFFFFF;
				text-shadow: 0 0 10px rgba(2, 2, 2, 0.25), 0 0 10px rgba(2, 2, 2, 0.25), 0 0 10px rgba(2, 2, 2, 0.25), 0 0 10px rgba(2, 2, 2, 0.25), 0 0 10px rgba(2, 2, 2, 0.25), 0 0 10px rgba(2, 2, 2, 0.25);
			}
		}
	}

	button.more-categories {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		width: 385px;
		height: 120px;
		font-size: 18px;
		font-weight: 400;
		line-height: 1;
		border-radius: 25px;
		background-color: $main-white-color;
		filter: drop-shadow(-5px -5px 10px rgba(255, 255, 255, 0.5)) drop-shadow(5px 5px 10px rgba(170, 170, 204, 0.25)) drop-shadow(10px 10px 20px rgba(170, 170, 204, 0.5)) drop-shadow(-10px -10px 20px #FFFFFF);
		margin: auto;
		padding: 20px;

		&.view svg {
			transform: rotate(-90deg);
		}

		span {
			margin-top: 15px;
		}
	}
}

.expert-block {
	.expert-block-wrapper {
		max-width: 1200px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
		margin: auto;
	}

	.expert-cart {
		max-width: 270px;
		width: 100%;
		min-height: 150px;
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: #F4F6F5;
		box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(160, 160, 160, 0.25), 10px 10px 20px rgba(160, 160, 160, 0.5), -10px -10px 20px #FFFFFF;
		border-radius: 32px;
		padding: 20px;
		margin: 15px;

		.expert-cart-wrapper {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			img.user-avatar {
				width: 80px;
				height: 80px;
				object-fit: cover;
				border-radius: 50%;
				margin-right: 25px;
			}

			div.expert-data {

				p.expert {
					font-size: 20px;
					font-weight: 400;
				}

				div.expert-rate-block {
					position: relative;
					display: flex;
					flex-direction: row;
					align-items: center;
					margin-bottom: 10px;

					img.expert-rate-icon {
						width: 24px;
						height: 24px;
						margin-right: 5px;
					}

					div.expert-rate-wrapper {
						min-width: 82px;
						display: flex;
						flex-direction: column;
						align-items: flex-start;

						span.expert-rate-description {
							font-size: 12px;
							font-weight: 500;
							line-height: 1;
						}
					}

					p.finally-jobs {
						font-size: 9px;
						font-weight: 500;
						line-height: 1;
						margin-top: 5px;
					}
				}

				a.expert-cart-see-more {
					font-size: 9px;
					font-weight: 600;
					line-height: 1;
					padding: 4px 16px;
				}
			}
		}
	}
}

.how-do-we-work {
	background-color: rgba(255, 165, 61, 0.06);
	padding: 50px 0;

	h2 {
		margin-bottom: 50px;
	}

	.work-block {
		max-width: 1085px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto;

		.work-block__item {
			position: relative;
			width: 240px;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 50px;

			&:first-of-type .work-block__image-block::after {
				content: "";
				position: static;
				top: 50%;
				left: 110%;
				width: 227px;
				height: 67px;
				background: url("../image/icons/step2.svg") no-repeat;
				background-size: contain;
			}

			&:nth-child(2) .work-block__image-block::after {
				content: "";
				position: static;
				top: 50%;
				left: 97%;
				transform: translateY(-50%);
				width: 266px;
				height: 91px;
				background: url("../image/icons/step3.svg") no-repeat;
				background-size: contain;
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.work-block__image-block {
			position: relative;

			img {
				border-radius: 50%;
				margin-bottom: 20px;
			}

			.count {
				position: absolute;
				bottom: 10px;
				left: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background-color: $background-green-default;
				color: #FFFFFF;
				font-size: 31px;
				font-weight: 600;
			}
		}

		.work-block__description-block {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			text-align: center;
			min-height: 100px;

			h3 {
				font-size: 24px;
				font-weight: 500;
				line-height: 1.5;
			}

			p.mini-description {
				width: 170px;
				font-size: 16px;
				font-weight: 300;
				line-height: 1.5;
				margin: 0 auto;
			}
		}
	}
}

.create-an-order-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 35px;
	padding: 0 5px;

	p {
		text-align: center;
		font-weight: 400;
		font-size: 20px;
		margin-bottom: 25px;
	}

	.create-an-order {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		max-width: 480px;
		width: 100%;
		height: 70px;
		font-weight: 600;
		font-size: 22px;
	}
}

.testimonials {
	max-width: 1085px;
	margin: auto;
	padding: 50px 0;

	.testimonials-container {
		position: relative;
		max-width: 1045px;
		margin: auto;
	}

	.swiper {
		padding: 0 10px 40px 10px;

		.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
			bottom: 0;
		}

		.swiper-pagination-bullet {
			width: 12px;
			height: 12px;
			background-color: $background-green-default;
			opacity: 1;
			margin-right: 5px;
		}

		.swiper-pagination-bullet.swiper-pagination-bullet-active {
			background-color: #003700;
			opacity: 1;
		}
	}

	h2 {
		margin-bottom: 25px;
	}

	.swiper-button-group {
		display: none;
		position: absolute;
		top: 0;
		right: 10px;
		width: 110px;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.swiper-button {
			width: 50px;
			height: 50px;
			background-color: #FAFAFA;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: -3px -3px 10px rgba(255, 255, 255, 0.5), 3px 3px 10px rgba(170, 170, 204, 0.25), 5px 5px 20px rgba(170, 170, 204, 0.5), -5px -5px 20px #FFFFFF;
			border-radius: 9px;
		}
	}

	.testimonial {
		position: relative;
		max-width: 500px;
		min-height: 250px;
		border-radius: 20px;
		background-color: #FAFAFA;
		box-shadow: 0 5px 10px rgba(151, 151, 151, 0.2), 0 10px 15px rgba(151, 151, 151, 0.2);
		padding: 25px;

		svg {
			position: absolute;
			top: 13px;
			right: 12px;
			width: 35px;
			height: 30px;
		}

		.user-meta-block {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			margin-bottom: 20px;

			.user-avatar {
				width: 60px;
				height: 60px;
				margin-right: 20px;
				border-radius: 17px;
				object-fit: cover;
			}

			.user-full-name {
				font-size: 22px;
				font-weight: 400;
				line-height: 1.5;
			}

			.user-place {
				font-size: 17px;
				font-weight: 300;
				line-height: 1.5;
			}
		}

		.user-text {
			font-size: 20px;
			font-weight: 300;
			line-height: 1.5;
		}
	}
}

footer {
	background-color: $background-green-default;
	color: $light-green-color;
	min-height: 340px;
	padding: 20px 20px;

	h3 {
		text-align: center;
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 10px;
	}

	.content {
		max-width: 1240px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		.about,
		.orders,
		.contacts {
			max-width: 680px;
		}

		.about {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-bottom: 50px;

			p {
				text-align: center;
				margin-bottom: 30px;
			}

			.terms {
				display: flex;
				flex-direction: row;
				justify-content: center;

				a {
					font-size: 15px;
					color: $light-green-color;

					&:first-of-type {
						margin-right: 25px;
					}
				}
			}
		}

		.orders {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			order: -1;
			margin-bottom: 50px;

			.orders-block {
				max-height: 240px;
				overflow-y: scroll;

				&::-webkit-scrollbar {
					width: 5px;
				}

				&::-webkit-scrollbar-track {
					background-color: #e0e0e0;
					border-radius: 2.3px;
				}

				&::-webkit-scrollbar-thumb {
					background-color: #4A4545;
					border-radius: 2.3px;

				}

				.order-item {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding-bottom: 5px;

					img {
						width: 34px;
						height: 34px;
						border-radius: 50%;
						object-fit: cover;
						margin-right: 10px;
					}

					p.user-task-des {
						max-width: 500px;
						font-size: 16px;
						font-weight: 400;
						line-height: 1.5;

						a.user-task-title {
							color: $text-dark-color;
						}

						a.user-city {
							color: $light-green-color;
						}
					}
				}
			}
		}

		.contacts {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-bottom: 50px;

			.contacts-block {
				font-weight: 300;
				text-align: center;
				margin-bottom: 15px;
			}

			.callback-form__header {
				text-align: center;

				span {
					display: block;
					font-size: 12px;
					font-weight: 300;
				}
			}

			.number-label {
				position: relative;
				border-bottom: 1px solid $light-green-color;

				#number.number {
					width: 100%;
					background-color: transparent;
					color: $light-green-color;
					margin-bottom: 3px;
					border: none;
					font-size: 12px;
				}

				button {
					position: absolute;
					right: 0;

					&:hover {
						right: -2px;
						transition: right 200ms linear;
					}
				}
			}

			.social-block {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-top: 15px;

				a {
					display: flex;
					flex-direction: row;
					align-items: center;
					font-size: 15px;
					color: $light-green-color;

					svg {
						margin-right: 5px;
					}
				}
			}
		}
	}

	.rights {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 15px;

		img.logo {
			max-width: 170px;
			height: 25px;
		}

		p.copyright {
			text-align: center;
			color: #B1CCC1;
		}
	}
}

.form-container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-x: visible;
	overflow-y: scroll;
	z-index: 100;
}

.overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .6);
	z-index: 0;
}

.form {
	max-width: 390px;
	width: 100%;
	background: #FFFEFC;
	box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	padding: 30px 10px 15px;
	z-index: 1;
}

.registration-form,
.login-form {
	position: relative;

	h3 {
		text-align: center;
		font-size: 24px;
		font-weight: 500;
		line-height: 1;
		color: $text-dark-color;
		margin-bottom: 20px;
	}

	.role-button-tabs {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;

		button.role-button {
			width: calc(50% - 5px);
			max-width: 173px;
			height: 42px;
			font-size: 20px;
			font-weight: 600;
			color: $light-green-color;
			background-color: $background-green-default;
			border-radius: 10px;

			&:hover {
				box-shadow: 0 0 10px $background-green-default;
			}

			&.active {
				background-color: $light-green-color;
				color: #6B6A69;
				box-shadow: inset 0 2px 5px rgba(151, 151, 151, 0.5), inset 0 3px 5px rgba(151, 151, 151, 0.25);
				border-radius: 11px;
			}
		}
	}

	.social-buttons-block {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 10px;

		.social-button {
			width: calc(50% - 5px);
			min-height: 54px;
			position: relative;
			border-radius: 8px;
			font-size: 20px;
			line-height: 1;
			font-weight: 500;
			text-align: center;
			transition: transform .1s linear;

			&:hover,
			&:focus-visible {
				transform: translateY(-3px);
				transition: transform .1s linear;
			}

			&:active {
				transform: translateY(0);
				transition: transform .1s linear;
			}

			&::before {
				content: "";
				width: 23px;
				height: 23px;
				background-size: contain;
				position: absolute;
				top: 15px;
				left: 16px;
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.facebook-button {
			background-color: #1877F2;
			color: #FFFFFF;

			&::before {
				background-image: url("../image/icons/FacebookLogo.svg");
			}
		}

		.google-button {
			background-color: #FEFEFE;
			box-shadow: 0 0 3px rgba(0, 0, 0, 0.084), 0 2px 3px rgba(0, 0, 0, 0.168);

			&::before {
				background-image: url("../image/icons/GoogleLogo.svg");
			}
		}

		.apple-button {
			background-color: #000000;
			color: #FFFFFF;

			&::before {
				background-image: url("../image/icons/AppleLogo.svg");
			}
		}

		.microsoft-button {
			background-color: #FEFEFE;
			box-shadow: 0 0 3px rgba(0, 0, 0, 0.084), 0 2px 3px rgba(0, 0, 0, 0.168);

			&::before {
				background-image: url("../image/icons/MicrosoftLogo.svg");
			}
		}
	}

	.orWrapper {
		position: relative;
		margin: 10px 0;
		display: flex;
		justify-content: center;

		&::before {
			content: "";
			width: 100%;
			height: 1px;
			background-color: #28343B;
			position: absolute;
			top: 50%;
			left: 0;
			z-index: 0;
		}

		.or {
			position: relative;
			text-align: center;
			font-size: 14px;
			font-weight: 500;
			line-height: 1;
			color: #28343B;
			//text-transform: uppercase;
			background-color: #FFFEFC;
			padding: 0 7px;
			z-index: 1;
		}
	}

	.form-block {
		.error-alert {
			padding: 0 6px 6px;
			color: tomato;
			font-size: 12px;
			line-height: 1.2;
			margin-top: 3px;
		}

		.input-container {
			position: relative;
			width: 100%;
			height: 50px;
			border: 4px solid #979494;
			border-radius: 10px;
			padding: 2px;
			margin-bottom: 10px;

			&.error {
				//border-color: tomato;
				margin-bottom: 0;
			}

			& svg {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(-50%, -50%);
				cursor: pointer;
			}
		}

		input.form-input {
			border: none;
			width: 100%;
			height: 100%;
			font-size: 18px;
			font-weight: 400;
			line-height: 24px;
			color: #979494;
			padding: 7px 0 7px 14px;
		}

		.cut {
			height: 20px;
			position: absolute;
			left: 18px;
			top: -21px;
			opacity: 0;
			transition: transform 200ms, opacity 200ms;
			background-color: #FFFFFF;
			font-size: 13px;
			line-height: 1;
			color: transparent;
			padding: 1px 5px;
		}

		.form-input:focus ~ .cut,
		.form-input:not(:placeholder-shown) ~ .cut {
			transform: translateY(9px);
			opacity: 1;
		}

		.placeholder {
			line-height: 14px;
			color: #979494;
			pointer-events: none;
			position: absolute;
			top: 14px;
			left: 20px;
			transform-origin: 0 50%;
			transition: transform 200ms, color 200ms, opacity 200ms;
		}

		.form-input:focus ~ .placeholder,
		.form-input:not(:placeholder-shown) ~ .placeholder {
			transform: translateY(-23px) translateX(6px) scale(0.75);
		}

		.terms-n-policy,
		.remember-me {
			.custom-check {
				position: relative;
				margin-bottom: 10px;

				input.terms-n-policy-check,
				input.check {
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					opacity: 0;
					z-index: -1;

					& + label {
						display: inline-flex;
						align-items: center;
						flex-wrap: wrap;
						font-size: 13px;
						line-height: 1.2;
						font-weight: 400;
						color: #737A84;

						&::before {
							content: '';
							display: inline-block;
							width: 13px;
							height: 13px;
							margin-right: 8px;
							background-repeat: no-repeat;
							background-position: center;
							cursor: pointer;
							background-image: url("../image/icons/unchecked.svg");
							background-size: contain;
							border: none;
							border-radius: 2px;
						}

						a {
							color: $main-black-color;
						}
					}

					&:checked + label::before {
						background-color: $light-green-color;
						background-image: url("../image/icons/checked.svg");
					}
				}
			}
		}

		button.continue {
			//max-width: 350px;
			width: 100%;
			height: 50px;
			font-size: 18px;
			font-weight: 400;
			line-height: 1;
			//margin-bottom: 20px;
		}
	}

	.form-footer {
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		&::after {
			position: absolute;
			left: 0;
			bottom: 54px;
			content: "";
			width: 100%;
			height: 1px;
			background-color: #28343B;
		}

		.form-footer-wrapper {
			span {
				font-size: 14px;
				font-weight: 400;
				line-height: 1;
			}

			.signIn-button {
				margin-left: 10px;
				font-size: 16px;
				font-weight: 400;
				line-height: 1;
			}
		}
	}
}

.login-form {
	.form-block {
		.remember-me {
			.custom-check {
				margin-bottom: 0;
			}
		}
	}

	.wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 20px 0;
	}
}

.google-map-block {
	margin: 25px 0;
	padding: 0 10px;

	.search-expert-form {
		max-width: 350px;
		margin: auto;

		.search-expert-form-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			margin-bottom: 25px;

			.select-categories-wrapper {
				max-width: 330px;
				width: 100%;
				margin: 0 0 20px;

				& > div {
					width: 100%;
					height: 50px;
					margin: 0;
				}

				fieldset {
					border: 4px solid #979494;
					top: -3.6px;
				}

				#select-categories {
					padding: 13px 32px 14px 20px;
				}

				.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
					top: -3px;
					left: 10px;
				}

				svg {
					right: 10px;
					top: 50%;
					transform: translate(-50%, -50%);

					&.MuiSelect-iconOpen {
						transform: translate(-50%, -50%) rotate(180deg);
					}
				}
			}

			.bounds-wrapper {
				max-width: 330px;
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}

			.autocomplete {
				position: relative;
				max-width: 330px;
				width: 100%;
				height: 50px;
				margin: 0 0 20px;

				&::after {
					content: "";
					position: absolute;
					top: 50%;
					left: 15px;
					transform: translateY(-50%);
					background: url("../image/icons/marker.svg") no-repeat;
					background-size: contain;
					width: 17px;
					height: 24px;
				}

				#autocomplete {
					position: relative;
					max-width: 350px;
					width: 100%;
					height: 50px;
					border: 4px solid #979494;
					padding: 9px 43px;
					font-weight: 300;
					font-size: 16px;
				}
			}

			.select-bound-wrapper {
				#select-bound {
					padding: 13px 32px 14px 20px;
				}

				fieldset {
					border: 4px solid #979494;
					top: -3.6px;
				}

				svg {
					right: 10px;
					top: 50%;
					transform: translate(-50%, -50%);

					&.MuiSelect-iconOpen {
						transform: translate(-50%, -50%) rotate(180deg);
					}
				}
			}

			button.find-button {
				width: 140px;
				height: 50px;
				background: $background-green-default;
				border-radius: 4px;
				font-weight: 500;
				font-size: 18px;
				color: $main-white-color;
				margin-left: 5px;
			}
		}
	}

	.google-map-wrapper {
		box-shadow: 0 4.61667px 9.23333px rgba(151, 151, 151, 0.25), 0 9.23333px 18.4667px rgba(151, 151, 151, 0.25);
		border-radius: 14px;
		overflow: hidden;

		.zoom-control {
			height: 65px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			top: 50%;
			right: 10px !important;
			transform: translateY(-50%);

			button {
				width: 30px;
				height: 30px;
				background-color: #FFFFFF;
				border-radius: 50%;
				font-size: 20px;
			}
		}
	}

	.expert-cart {
		width: 100%;
		min-height: 82px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		img.user-avatar {
			width: 65px;
			height: 65px;
			object-fit: cover;
			border-radius: 50%;
			margin-right: 25px;
		}

		.expert-data {

			p.expert {
				font-size: 20px;
				font-weight: 400;
			}

			div.expert-rate-block {
				position: relative;
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 10px;

				img.expert-rate-icon {
					width: 24px;
					height: 24px;
					margin-right: 5px;
				}

				div.expert-rate-wrapper {
					min-width: 82px;
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					span.expert-rate-description {
						font-size: 12px;
						font-weight: 500;
						line-height: 1;
					}
				}

				p.finally-jobs {
					font-size: 9px;
					font-weight: 500;
					line-height: 1;
					margin-top: 5px;
				}
			}

			a.expert-cart-see-more {
				font-size: 9px;
				font-weight: 600;
				line-height: 1;
				padding: 4px 16px;
			}
		}
	}
}

#map {
	width: 100%;
	height: 600px;
}

.account-page {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		left: -100px;
		bottom: -270px;
		background: url("../image/icons/gear.svg") no-repeat;
		background-size: 100%;
		width: 945px;
		height: 887px;
		opacity: 0.1;
		z-index: 0;
	}

	.content {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 270px;
		z-index: 1;
	}

	h3 {
		font-weight: 600;
		font-size: 16px;
		text-shadow: 0 0 15px rgba(255, 255, 255, 0.93);
		margin-bottom: 7px;
	}

	.left-block {
		width: 100%;

		.personal-data-block {
			margin-bottom: 25px;

			.personal-data-wrapper {
				@include box-shadow-with-border-radius;
				padding: 25px 35px;
				display: flex;
				flex-direction: column;
				align-items: center;

				img.avatar {
					width: 190px;
					height: 190px;
					object-fit: cover;
					border-radius: 13px;
					margin-bottom: 25px;
				}

				.data-block {
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.username {
						font-weight: 500;
						font-size: 24px;
						margin-bottom: 10px;
					}

					.contact-block {

						p {
							font-weight: 300;
							font-size: 15px;
							padding-left: 35px;
							margin-bottom: 10px;
						}

						p.user-phone {
							position: relative;

							&::after {
								content: "";
								position: absolute;
								left: 0;
								background: url("../image/icons/phone.svg") no-repeat;
								background-size: contain;
								width: 24px;
								height: 24px;
							}
						}

						p.user-email {
							position: relative;

							&::after {
								content: "";
								position: absolute;
								left: 0;
								background: url("../image/icons/mail-convert.svg") no-repeat;
								background-size: contain;
								width: 24px;
								height: 24px;
							}
						}
					}
				}
			}
		}

		.switch-block {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-bottom: 25px;

			.MuiFormControl-root {
				.css-1q36rmd-MuiStack-root > :not(style) + :not(style) {
					margin-left: 10px;
				}

				p {
					max-width: 160px;
					width: 100%;
					font-weight: 400;
					font-size: 18px;

					&:first-of-type {
						text-align: right;
					}
				}

				&:first-of-type {
					margin-bottom: 17px;
				}
			}
		}

		.notifications-block {
			margin-bottom: 25px;

			.notifications-wrapper {
				@include box-shadow-with-border-radius;
				padding: 15px 25px;
			}

			label {
				margin: 0 0 0 -9px;

			}
		}

		.auth-block {
			margin-bottom: 50px;

			.auth-wrapper {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				@include box-shadow-with-border-radius;
				padding: 30px 15px;

				.MuiFormControlLabel-root {
					margin: 0;
				}

				.MuiSwitch-root {
					margin-left: 10px;
				}
			}
		}

		.gallery-block {
			.gallery-control-buttons {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 25px;

				button {
					margin-left: 20px;
					font-weight: 600;
					font-size: 16px;
					text-transform: uppercase;
				}

				button.sort-by-button {
					position: relative;
					font-weight: 600;
					font-size: 16px;
					text-transform: uppercase;
					color: #309CFF;

					&::after {
						content: "";
						position: absolute;
						top: -3px;
						left: -24px;
						background: url("../image/icons/sort-by.svg") no-repeat;
						background-size: contain;
						width: 24px;
						height: 24px;
					}
				}

				label.add-button-label {
					position: relative;
					cursor: pointer;
					color: #309CFF;
					font-weight: 600;
					font-size: 16px;
					text-transform: uppercase;

					.add-button {
						position: absolute;
						opacity: 0;
						width: 0;
						height: 0;
					}
				}

				#edit-button {
					color: #979494;
				}

				#delete-button {
					color: $main-warning-color;
				}
			}

			.gallery {
				position: relative;
				width: 100%;
				height: 920px;
				overflow-x: scroll;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;

				&::-webkit-scrollbar {
					height: 5px;
				}

				&::-webkit-scrollbar-track {
					background-color: #e0e0e0;
					border-radius: 2.3px;
				}

				&::-webkit-scrollbar-thumb {
					background-color: #4A4545;
					border-radius: 2.3px;

				}

				.img-wrapper {
					max-width: 180px;
					max-height: 180px;
					padding-right: 25px;
					padding-bottom: 25px;

					img {
						object-fit: cover;
					}
				}
			}
		}
	}

	.right-block {
		width: 100%;
		@include box-shadow-with-border-radius;
		margin-top: 34px;

		.edit-personal-data,
		.since-address-about-block,
		.user-categories-block,
		.phone-password-email {
			position: relative;
			padding: 20px 5px;
			width: 100%;

			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				border: 1px dashed #759A8A;
				filter: blur(4px);
				opacity: .4;
			}
		}

		.edit-personal-data {
			textarea {
				resize: none !important;
			}

			p.desLength {
				position: absolute;
				bottom: 13px;
				right: 13px;
				font-weight: 500;
				font-size: 8px;
				line-height: 1;
				color: #759A8A;
				opacity: 0.7;
			}

			.username-block {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				height: 30px;
				margin-bottom: 12px;

				p:first-of-type {
					font-size: 14px;
					font-weight: 600;
				}

				p.username {
					padding-left: 16px;
					text-align: center;
				}
			}
		}

		.input-block {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			margin-bottom: 12px;

			p {
				white-space: nowrap;
				font-size: 14px;
				font-weight: 600;
				margin-bottom: 10px;
			}

			.wrapper {
				position: relative;
				width: 100%;

				svg {
					position: absolute;
					top: 15px;
					right: 15px;
					width: 20px;
					height: 20px;
					cursor: pointer;
				}
			}

			input {
				width: 100%;
				height: 50px;
				border: 4px solid #979494;
				border-radius: 10px;
				padding: 10px 35px 10px 16px;
				font-family: "Poppins", sans-serif;
				font-size: 16px;
				font-weight: 400;
				color: $main-black-color;
			}

			textarea {
				width: 100%;
				height: 150px;
				min-height: 150px;
				border: 4px solid #979494;
				border-radius: 10px;
				resize: vertical;
				padding: 10px 35px 10px 16px;
				font-family: "Poppins", sans-serif;
				font-weight: 300;
				font-size: 14px;
				letter-spacing: 0.045em;

				&::-webkit-scrollbar {
					width: 5px;
				}

				&::-webkit-scrollbar-track {
					background-color: #e0e0e0;
					border-radius: 2.3px;
				}

				&::-webkit-scrollbar-thumb {
					background-color: #4A4545;
					border-radius: 2.3px;
				}
			}
		}

		.since-address-about-block {

			.user-address-block {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.wrapper {
					position: relative;

					&::after {
						content: "";
						position: absolute;
						left: 20px;
						top: 10px;
						background: url("../image/icons/marker.svg") no-repeat;
						width: 20px;
						height: 26px;
					}

					.user-address {
						padding-left: 50px;
						margin-left: 0;
					}
				}
			}

			.date-picker-block {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-bottom: 12px;

				p {
					font-size: 14px;
					font-weight: 600;
					margin-bottom: 10px;
				}

				.wrapper {
					width: 100%;
					//height: 370px;
					//border: 4px solid #979494;
					//border-radius: 10px;
					//padding: 2px;

					& > div {
						width: 100%;
					}

					fieldset {
						border: 4px solid #979494;
						border-radius: 10px;
					}

					.MuiPickerStaticWrapper-root {
						min-width: 270px;
						width: 100%;

						& > div {

							& > div {
								max-width: 320px;
								width: 100%;
							}
						}

						.MuiCalendarPicker-root,
						.MuiMonthPicker-root {
							max-width: 320px;
							width: 100%;
							margin: 0;
						}

						.MuiYearPicker-root {
							button {
								width: 100%;
							}
						}
					}

					.MuiPickersDay-root.Mui-selected,
					.Mui-selected {
						background-color: $background-green-default;
					}

					.PrivatePickersFadeTransitionGroup-root {

						&::-webkit-scrollbar {
							width: 5px;
						}

						&::-webkit-scrollbar-track {
							background-color: #e0e0e0;
							border-radius: 2.3px;
						}

						&::-webkit-scrollbar-thumb {
							background-color: #4A4545;
							border-radius: 2.3px;

						}
					}
				}
			}
		}

		.user-categories-block {
			button {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				min-width: 80px;

				&.open {
					svg {
						transform: rotate(180deg);
					}
				}
			}

			.user-categories-wrapper {
				height: 200px;
				overflow: hidden;
				transition: height 200ms linear;

				&.open {
					height: auto;
					transition: height 300ms linear;
				}

				ul.user-categories-content {
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
					align-content: flex-start;

					li.user-categories-content-item {
						position: relative;
						font-weight: 300;
						font-size: 16px;
						padding-left: 20px;

						&::after {
							content: "";
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
							width: 10px;
							height: 10px;
							border-radius: 50%;
							background-color: $background-green-default;
						}
					}
				}
			}
		}

		.button-block {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			button {
				min-width: 110px;
				height: 50px;
				background-color: $background-green-default;
				font-weight: 600;
				font-size: 16px;
				color: #EEFFF9;
				border-radius: 10px;
				padding: 0 30px;
			}
		}

		.change-password-wrapper,
		.user-phone-edit-wrapper {
			& > div {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				margin-bottom: 12px;

				label {
					font-weight: 400;
					font-size: 14px;
				}

				.wrapper {
					margin-left: 25px;
				}

				input {
					width: 370px;
					height: 50px;
					border: 4px solid #979494;
					border-radius: 10px;
					padding: 10px 16px;
					font-family: "Poppins", sans-serif;
					font-size: 16px;
					font-weight: 400;
					color: $main-black-color;
				}
			}
		}

		.user-phone-edit-wrapper {
			.phone1-block {
				flex-wrap: wrap;

				.button-block {
					margin-top: 12px;
				}
			}

			input:disabled {
				border: none;
				background-color: transparent;
			}
		}

		.change-email-wrapper {
			.show-current-email {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				margin-bottom: 10px;

				.help-text {
					font-weight: 600;
					font-size: 14px;
					margin-bottom: 10px;
				}

				.current-user-email {
					max-width: 370px;
					width: 100%;
					font-weight: 500;
					font-size: 16px;
				}
			}
		}

		.delete-account-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 30px 0;

			button {
				width: 200px;
				height: 50px;
				border-radius: 8px;
				background-color: $main-warning-color;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				color: $main-white-color;
				font-weight: 700;
				font-size: 18px;
			}
		}
	}
}

.profile-page {
	h1 {
		margin-bottom: 50px;
	}

	h3 {
		text-align: center;
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 25px;
	}

	.content {

		header {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 50px;

			.user-avatar-block {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				max-width: 300px;
				width: 100%;
				margin-bottom: 50px;

				img.avatar {
					width: 180px;
					height: 180px;
					border-radius: 50%;
					object-fit: cover;
					margin-bottom: 20px;
				}

				.username-block {
					.username-wrapper {
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
					}

					p.username {
						font-size: 36px;
						line-height: 54px;
						text-align: center;
					}

					p.user-description {
						font-weight: 300;
						font-size: 14px;
						text-align: center;
						line-height: 19px;
						letter-spacing: 0.045em;
						margin-bottom: 20px;
					}
				}

				.send-message,
				.offer-button {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					max-width: 300px;
					width: 100%;
					height: 50px;
					font-weight: 500;
					font-size: 18px;
					color: #EEFFF9;
					border-radius: 10px;
					margin-bottom: 10px;
				}

				.send-message {
					background-color: #309CFF;
				}

				.offer-button {
					background-color: $background-green-default;
				}
			}

			.user-self-block {
				.user-about-block {
					margin-bottom: 25px;

					p.user-about {
						text-align: center;
					}
				}
			}

			.summary {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: flex-start;
				max-width: 350px;
				margin: 25px auto 0 auto;

				.left-block,
				.right-block {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: space-between;
					flex-grow: 1;
					min-height: 180px;
				}

				p {
					position: relative;
					font-weight: 400;
					font-size: 16px;
					padding-left: 40px;

					&::after {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 24px;
						height: 24px;
					}
				}

				.phone-block {
					position: relative;
					margin-bottom: 20px;

					&::after {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						background: url("../image/icons/phone.svg") no-repeat center/contain;
						width: 24px;
						height: 24px;
					}
				}

				p.user-email {
					margin-bottom: 20px;

					&::after {
						background: url("../image/icons/mail-convert.svg") no-repeat center/contain;
					}
				}

				p.user-address {
					margin-bottom: 20px;

					&::after {
						background: url("../image/icons/marker.svg") no-repeat center/contain;
					}
				}

				p.user-since {
					margin-bottom: 20px;

					&::after {
						background: url("../image/icons/flag.svg") no-repeat center/contain;
					}
				}

				p.user-available-work {
					margin-bottom: 20px;

					&::after {
						width: 18px;
						height: 18px;
						top: 50%;
						transform: translateY(-50%);
						border-radius: 50%;
					}

					&.ready::after {
						background-color: $background-green-default;
					}

					&.busy::after {
						background-color: $main-warning-color;
					}
				}

				p.user-online {
					margin-bottom: 20px;

					&::after {
						width: 18px;
						height: 18px;
						top: 50%;
						transform: translateY(-50%);
						border-radius: 50%;
					}

					&.online::after {
						background-color: $background-green-default;
					}

					&.offline::after {
						background-color: darkgrey;
					}
				}
			}
		}
	}

	.main-content {
		margin-bottom: 225px;

		.user-rate-block {
			margin: 0 auto 50px;

			.user-rate-wrapper {
				@include inner-shadow-top;
				padding: 25px 10px;

				.content-inner-block {
					max-width: 570px;
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;
					margin: auto;
				}

				img.user-rate-icon {
					width: 70px;
					height: 70px;
				}

				.user-description-wrapper {
					display: flex;
					flex-direction: column;
					align-items: center;
					color: #4250AF;
					margin: 25px 0;

					.user-rate-description {
						font-weight: 600;
						font-size: 24px;
						color: inherit;
					}

					.finally-jobs {
						font-weight: 300;
						font-size: 11px;
						color: inherit;
					}
				}
			}

			.read-review-button {
				width: 195px;
				height: 45px;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				color: $main-black-color;
				background-color: $main-white-color;
				box-shadow: 0 2px 7px rgba(151, 151, 151, 0.5), 0 5px 10px rgba(151, 151, 151, 0.25);
				border-radius: 10px;
			}
		}

		.user-categories-block {
			margin: 0 auto 50px;

			button {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				min-width: 80px;

				&.open {
					svg {
						transform: rotate(180deg);
					}
				}
			}

			.user-categories-wrapper {
				height: 100px;
				overflow: hidden;
				@include inner-shadow-top;
				padding: 25px 10px;

				&.open {
					height: auto;
				}

				ul.user-categories-content {
					display: flex;
					flex-direction: column;

					li.user-categories-content-item {
						position: relative;
						font-weight: 300;
						font-size: 14px;
						padding-left: 20px;
						margin-right: 30px;

						&::after {
							content: "";
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
							width: 10px;
							height: 10px;
							border-radius: 50%;
							background-color: $background-green-default;
						}
					}
				}
			}
		}

		.google-map-block {
			margin: 0 auto 50px;

			.google-map-wrapper {
				border-radius: 10px;
			}
		}

		.gallery-block {
			max-width: 875px;
			width: 100%;
			margin: auto;

			.gallery-control-buttons {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 25px;

				button {
					margin-left: 20px;
					font-weight: 600;
					font-size: 16px;
					text-transform: uppercase;
				}

				button.sort-by-button {
					position: relative;
					font-weight: 600;
					font-size: 16px;
					text-transform: uppercase;
					color: #309CFF;

					&::after {
						content: "";
						position: absolute;
						top: -3px;
						left: -24px;
						background: url("../image/icons/sort-by.svg") no-repeat;
						background-size: contain;
						width: 24px;
						height: 24px;
					}
				}
			}

			.gallery {
				position: relative;
				width: 100%;
				height: 550px;
				overflow-x: scroll;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;

				&::-webkit-scrollbar {
					height: 5px;
				}

				&::-webkit-scrollbar-track {
					background-color: #e0e0e0;
					border-radius: 2.3px;
				}

				&::-webkit-scrollbar-thumb {
					background-color: #4A4545;
					border-radius: 2.3px;

				}

				.img-wrapper {
					max-width: 180px;
					max-height: 180px;
					padding-right: 25px;
					padding-bottom: 25px;

					img {
						object-fit: cover;
					}
				}
			}
		}
	}
}

@media (max-width: 575px) {
	.form-container {
		padding: 10px;
	}
	header.MuiAppBar-root {
		.content {
			width: 100%;

			& > div:first-of-type {
				justify-content: space-between;
			}
		}
	}
}

@media (min-width: 550px) {
	.account-page {
		.content {
			.left-block {
				.personal-data-block {
					.personal-data-wrapper {
						flex-direction: row;

						img.avatar {
							margin-bottom: 0;
						}

						.data-block {
							height: 190px;
							margin-left: 15px;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 576px) {
	.container {
		padding: 0 18px;
	}
	header.sticky {
	}
	header.MuiAppBar-root {
		.content {
			max-width: 540px;
		}
	}
	.profile-page {
		.content {
			header {
				.summary {
					flex-direction: row;
					align-items: flex-start;
					max-width: none;
				}
			}

			.user-categories-block {
				.user-categories-wrapper {
					ul.user-categories-content {
						flex-direction: row;
						flex-wrap: wrap;
					}
				}
			}
		}
	}
}

@media (min-width: 768px) {
	h1 {
		font-size: 25px;
	}
	.container {
		padding: 0 24px;
	}
	.create-an-order-wrapper {
		p {
			font-size: 26px;
		}
	}
	header.MuiAppBar-root {
		padding: 30px 0;

		.content {
			max-width: 720px;

			.logo-link {
				max-width: none;
				height: auto;
			}

			.dynamic-icons-wrapper {

			}

			.login-button-block {
				width: 251px;
			}

			/*.left-block {
				width: auto;
				margin-right: 50px;
			}*/

			/*.right-block {
				width: auto;
				position: static;
				height: auto;
				flex-direction: row;
				align-items: center;
				flex-grow: 1;
				justify-content: flex-end;
				background-color: transparent;
				padding: 0;
				margin-top: 0;
				z-index: 0;

				.create-an-order {
					margin-right: auto;
				}

				.language-button-group {
					margin-right: 50px;
				}

				button.create-an-order,
				.language-button-group {
					margin-bottom: 0;
				}
			}*/
		}
	}
	header.sticky {
		padding: 20px 0;
	}
	.banner-block {
		.swiper {
			.custom-swiper-button-next,
			.custom-swiper-button-prev {
				width: 50px;
				height: 50px;
			}
		}
	}
	.how-do-we-work {
		.work-block {
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;

			.work-block__item {
				margin-bottom: 0;

				&:first-of-type .work-block__image-block::after {
					position: absolute;
					top: 60%;
					left: 92%;
					width: 100px;
					height: 67px;
				}

				&:nth-child(2) .work-block__image-block::after {
					position: absolute;
					top: 60%;
					left: 96%;
					width: 90px;
					height: 31px;
				}
			}
		}
	}
	.testimonials {
		.testimonial {
			svg {
				width: 45px;
				height: 40px;
			}
		}
	}
	.profile-page {
		.content {
			.user-rate-block {
				.user-rate-wrapper {
					.content-inner-block {
						flex-direction: row;

						.user-description-wrapper {
							margin: 0;
						}
					}
				}
			}
		}
	}
	.account-page {
		.content {
			.left-block {
				.personal-data-block {
					.personal-data-wrapper {
						flex-direction: row;

						img.avatar {
							margin-bottom: 0;
						}

						.data-block {
							height: 190px;
							margin-left: 30px;
						}
					}
				}

				.switch-block {
					.MuiFormControl-root {
						.css-1q36rmd-MuiStack-root > :not(style) + :not(style) {
							margin-left: 25px;
						}
					}
				}

				.auth-block {
					.auth-wrapper {
						.MuiSwitch-root {
							margin-left: 25px;
						}
					}
				}
			}

			.right-block {
				.edit-personal-data,
				.since-address-about-block,
				.user-categories-block,
				.phone-password-email {
					padding: 50px;
				}

				.input-block {
					flex-direction: row;
					align-items: center;

					p {
						margin: 0;
					}

					.wrapper {
						margin-left: 25px;
					}

					.wrapper,
					input,
					textarea {
						max-width: 370px;
					}
				}

				.edit-personal-data {
					.username-block {
						justify-content: flex-end;

						p.username {
							max-width: 370px;
							width: 100%;
							margin-left: 25px;
							text-align: left;
						}
					}
				}

				.since-address-about-block {
					.date-picker-block {
						flex-direction: row;
						justify-content: flex-end;
						align-items: center;

						.wrapper {
							max-width: 370px;
							margin-left: 25px;
						}
					}
				}

				.change-email-wrapper {
					.show-current-email {
						flex-direction: row;
						justify-content: flex-end;
						align-items: center;

						.help-text {
							margin-bottom: 0;
						}

						.current-user-email {
							margin-left: 25px;
						}
					}
				}
			}
		}
	}
	.google-map-block {
		.search-expert-form {
			max-width: none;

			.search-expert-form-wrapper {
				justify-content: flex-end;

				.select-categories-wrapper {
					margin: 0 5px 20px 0;

				}
			}
		}
	}
}

@media (min-width: 1024px) {
	.form {
		padding: 30px 30px 15px;
	}
	h1 {
		font-size: 30px;
	}
	.container {
		padding: 0;
	}
	header.MuiAppBar-root {
		.content {
			max-width: 960px;
		}
	}
	.try-service-kart-block {
		.button-block {
			flex-direction: row;
		}
	}
	.how-do-we-work {
		.work-block {
			.work-block__item {
				&:first-of-type .work-block__image-block::after {
					top: 50%;
					left: 97%;
					width: 200px;
					height: 67px;
				}

				&:nth-child(2) .work-block__image-block::after {
					top: 60%;
					left: 93%;
					width: 210px;
					height: 72px;
				}
			}
		}
	}
	.testimonials {
		.swiper-button-group {
			display: flex;
		}
	}
	#scroll-to-top {
		right: 45px;
		width: 60px;
		height: 60px;

		svg {
			font-size: 50px;
		}
	}
	.profile-page {
		.content {
			header {
				flex-direction: row;
				align-items: flex-start;
				margin-bottom: 0;

				.user-avatar-block {
					margin-right: 50px;
					margin-bottom: 0;
				}
			}

			.user-rate-block,
			.user-categories-block,
			.google-map-block {
				max-width: 940px;
			}
		}
	}
	.account-page {
		.content {
			flex-direction: row;
			align-items: flex-start;

			.left-block {
				width: calc(50% - 35px);

				.personal-data-block {
					.personal-data-wrapper {
						flex-direction: column;

						img.avatar {
							margin-bottom: 25px;
						}

						.data-block {
							height: auto;
							margin-left: 15px;
						}
					}
				}
			}

			.right-block {
				width: 50%;

				.edit-personal-data,
				.since-address-about-block,
				.user-categories-block,
				.phone-password-email {
					padding: 30px;
				}

				.input-block {
					flex-direction: column;
					align-items: flex-start;

					p {
						margin: 0;
					}

					.wrapper {
						margin-left: 0;
					}

					.wrapper,
					input,
					textarea {
						max-width: none;
					}
				}

				.edit-personal-data {
					.username-block {
						justify-content: flex-end;

						p.username {
							max-width: none;
							width: 100%;
							margin-left: 0;
							text-align: left;
						}
					}
				}

				.since-address-about-block {
					.date-picker-block {
						flex-direction: column;
						justify-content: flex-end;
						align-items: flex-start;

						.wrapper {
							max-width: none;
							margin-left: 0;
						}
					}
				}

				.change-email-wrapper {
					.show-current-email {
						flex-direction: column;
						justify-content: flex-end;
						align-items: flex-start;

						.help-text {
							margin-bottom: 10px;
						}

						.current-user-email {
							margin-left: 0;
						}
					}
				}
			}
		}
	}
	.google-map-block {
		.search-expert-form {
			max-width: none;

			.search-expert-form-wrapper {
				justify-content: center;

				.select-categories-wrapper {
					max-width: 350px;
					margin: 0;

					fieldset {
						border-radius: 15px 0 0 15px;
					}
				}

				.autocomplete {
					margin: 0 5px;
				}

				.bounds-wrapper {
					max-width: none;
					width: auto;
				}
				button.find-button {
					border-radius: 0 15px 15px 0;
				}
			}
		}
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
	header.MuiAppBar-root {
		.content {
			max-width: 1140px;

			.central-block {
				padding: 0 10px 0 50px;
			}
		}
	}
	header.sticky {
		max-width: 1140px;
	}
	.banner-block {
		.swiper {
			.custom-swiper-button-next {
				right: 50px;
			}

			.custom-swiper-button-prev {
				left: 90px;
			}
		}
	}
	.how-do-we-work {
		.work-block {
			.work-block__item {
				&:first-of-type .work-block__image-block::after {
					top: 50%;
					left: 93%;
					width: 272px;
					height: 81px;
				}

				&:nth-child(2) .work-block__image-block::after {
					top: 50%;
					left: 97%;
					width: 266px;
					height: 91px;

				}
			}
		}
	}
	.account-page {
		.content {
			.left-block {
				.personal-data-block {
					.personal-data-wrapper {
						flex-direction: row;
						padding: 25px;

						img.avatar {
							margin-bottom: 0;
						}

						.data-block {
							height: 190px;
						}
					}
				}
			}

			.right-block {
				.edit-personal-data,
				.since-address-about-block,
				.user-categories-block,
				.phone-password-email {
					padding: 50px;
				}

				.input-block {
					flex-direction: row;
					align-items: center;

					p {
						margin: 0;
					}

					.wrapper {
						margin-left: 25px;
					}

					.wrapper,
					input,
					textarea {
						max-width: 370px;
					}
				}

				.edit-personal-data {
					.username-block {
						justify-content: flex-end;

						p.username {
							max-width: 370px;
							width: 100%;
							margin-left: 25px;
							text-align: left;
						}
					}
				}

				.since-address-about-block {
					.date-picker-block {
						flex-direction: row;
						justify-content: flex-end;
						align-items: center;

						.wrapper {
							max-width: 370px;
							margin-left: 25px;
						}
					}
				}

				.change-email-wrapper {
					.show-current-email {
						flex-direction: row;
						justify-content: flex-end;
						align-items: center;

						.help-text {
							margin-bottom: 0;
						}

						.current-user-email {
							margin-left: 25px;
						}
					}
				}
			}
		}
	}
	footer {
		.content {
			flex-direction: row;
			justify-content: space-between;

			.about,
			.orders,
			.contacts {
				min-height: 300px;
				margin-bottom: 0;
			}

			.about {
				width: 293px;
				margin-right: 10px;

				.terms {
					justify-content: flex-start;
				}
			}

			.orders {
				max-width: 460px;
				order: 0;
			}

			.contacts {
				width: 293px;
				margin-left: 10px;
			}
		}
	}
}

@media (min-width: 1300px) {
	.content {
		max-width: 1240px;
	}
}

@media (min-width: 1400px) {
	.container {
		max-width: 1320px;
	}
	header.sticky {
		max-width: 1320px;
	}
	footer .content .orders {
		max-width: 560px;
	}
	.try-service-kart-block {
		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			background: url("../image/work-man.png") no-repeat;
			background-size: contain;
			width: 196px;
			height: 357px;

		}
	}
	#scroll-to-top {
		right: 70px;
		width: 70px;
		height: 70px;

		svg {
			font-size: 60px;
		}
	}
	.account-page {
		.content {
			.left-block {
				.personal-data-block {
					.personal-data-wrapper {
						padding: 25px 35px;

						.data-block {
							margin-left: 30px;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1500px) {
	.container {
		max-width: 1440px;
	}
	header.sticky {
		max-width: 1440px;
	}
	.try-service-kart-block {
		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			background: url("../image/work-man.png") no-repeat;
			background-size: contain;
			width: 326px;
			height: 437px;

		}
	}
}
