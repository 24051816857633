*,
*:before,
*:after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
a,
a:hover,
a:active,
a:visited {
	text-decoration: none;
	outline: 0;
}
ul,
li {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
button {
	border: none;
	background-color: transparent;
	cursor: pointer;
}
button:focus-visible {
	outline: none;
}
input,
input:active {
	outline: none;
}
textarea {
	resize: none;
	border: none;
}
textarea:focus,
textarea:focus-within,
textarea:active {
	border: none;
	outline: none;
}
img {
	width: 100%;
	object-fit: contain;
}
body {
	font-size: 18px;
	line-height: 1.2;
	
}