button.navbar-toggle {
	position: relative;
	display: inline-block;
	width: 30px;
	height: 30px;
	margin: 25px 0;
}
button.navbar-toggle span {
	margin: 0 auto;
	position: relative;
	//right: 5px;
	-webkit-transition-duration: 0s;
	transition-duration: 0s;
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
button.navbar-toggle span:before{
	position: absolute;
	content: '';
	-webkit-transition-property: margin, -webkit-transform;
	transition-property: margin, transform;
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
	-webkit-transition-delay: 0.2s, 0s;
	transition-delay: 0.2s, 0s;
}
button.navbar-toggle span:after{
	position: absolute;
	content: '';
	-webkit-transition-property: margin, -webkit-transform;
	transition-property: margin, transform;
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
	-webkit-transition-delay: 0.2s, 0s;
	transition-delay: 0.2s, 0s;
}
button.navbar-toggle span, button.navbar-toggle span:before, button.navbar-toggle span:after {
	width: 30px;
	height: 3px;
	background-color: #000000;
	display: block;
	border-radius: 2px;
}
button.navbar-toggle span:before {
	margin-top: -12px;
}
button.navbar-toggle span:after {
	margin-top: 12px;
}
button.navbar-toggle.close span {
	background-color: rgba(0,0,0,0.0);
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
button.navbar-toggle.close span:before {
	margin-top: 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition-delay: 0s, 0.2s;
	transition-delay: 0s, 0.2s;
	box-shadow: 0 0 12px #D61389;
	background-color: #D61389;
}
button.navbar-toggle.close span:after {
	margin-top: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-transition-delay: 0s, 0.2s;
	transition-delay: 0s, 0.2s;
	box-shadow: 0 0 12px #D61389;
	background-color: #D61389;
}
nav.navbar-mobile a.navbar-brand{
	max-width: 40%;
}

@media (min-width: 768px) {
	button.navbar-toggle {
		display: none;
	}
}